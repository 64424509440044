import React, { useState } from "react";
import { SuccessScreen } from "./SuccessScreen";
import { RegisterScreen, SuccessStatus } from "./RegisterScreen";

export type RequestData = {
  name: string;
  email: string;
  hasTrackers: boolean;
  isMobiltrackerUser: boolean;
  isThirdPartUser: boolean;
  hasCustomers: boolean;
  hasSuppliers: boolean;
  cpf: string;
  address: string;
  cep: string;
  phone: string;
  hasBusiness: boolean;
  companyName: string;
  cnpj: string;
  website: string;
};

type AppState =
  | { tag: "wizard" }
  | { tag: "submit-success"; status: SuccessStatus };

const App: React.FC = () => {
  const [appState, setAppState] = useState<AppState>({
    tag: "wizard",
  });

  return appState.tag === "wizard" ? (
    <RegisterScreen
      onSubmitSuccess={(status) =>
        setAppState({ tag: "submit-success", status })
      }
    />
  ) : (
    <SuccessScreen status={appState.status} />
  );
};

export default App;
