import React from "react";
import logo from "./logo.svg";
import styled from "styled-components";

const Nav = styled.nav`
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .img-container {
    position: absolute;
    padding: 1rem 0;
    width: 50%;
    min-width: 15rem;
    max-width: 40rem;
    background-color: #27325c;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
    z-index: 3;
    left: -16rem;
    top: 60%;

    img {
      max-width: 20rem;
    }
  }

  .title {
    color: #27325c;
    padding: 0rem 1rem;
    margin: 2rem 0 1rem;
    width: 100%;
    /* background-color: yellow; */
    font-size: 2rem;
    font-weight: 500;
  }
  @media only screen and (max-width: 1000px) {
    .img-container {
      top: -1.5rem;
      left: 50%;
      transform: translateX(-50%) rotate(0deg);
      padding: 1rem;
    }
  }
`;

export function Header() {
  return (
    <Nav>
      <div className="img-container">
        <img src={logo} alt="Mobiltracker Partner Form" />
      </div>
      <h1 className="title">Formulário de Cadastro</h1>
    </Nav>
  );
}
