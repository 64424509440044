import React from "react";
import styled from "styled-components";
import { SuccessStatus } from "./RegisterScreen";

const Success = styled.div`
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.2);
  padding: 3rem;
  min-height: 40rem;
  max-width: 40rem;
  color: #27325c;
  position: relative;
  overflow: hidden;

  h4 {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.2rem;
    font-weight: 300;
  }

  span {
    font-weight: 700;
  }

  .decoration {
    width: 15rem;
    height: 15rem;
    position: absolute;
    bottom: 3rem;
    right: -8rem;
    transform: rotate(45deg);
    background-color: #27325c;
  }

  .btn-back {
    background-color: #32b266;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 3rem;
    align-self: flex-end;
    margin-right: 1rem;
    color: #fff;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    text-decoration: none;
    font-size: 1.3rem;

    &:before {
      content: "";
      /* transform: rotate(45deg); */
      position: absolute;
      width: 3rem;
      right: 0;
      height: 100%;
      background: linear-gradient(45deg, #32b266 50%, #fff 50%);
    }
  }

  @media only screen and (max-width: 650px) {
    box-shadow: none;
    /* background-color: red; */
    height: 100vh;
    margin: 0;

    .decoration {
      width: 15rem;
      height: 15rem;
      position: absolute;
      bottom: 3rem;
      right: -8rem;
      transform: rotate(45deg);
      background-color: #27325c;
    }
  }
`;

export function SuccessScreen({ status }: { status: SuccessStatus }) {
  return (
    <>
      <Success>
        <div className="alert-success">
          <h4>Parabéns!</h4>
          <p>
            <span>Seu pré-cadastro foi efetuado com sucesso!</span>
          </p>

          {status === "OK" ? (
            <>
              <p>
                Em alguns minutos você receberá um <span>e-mail</span> com o
                assunto "<span>Cadastro Mobiltracker para PARCEIROS</span>".
                Leia o email com atenção, pois ele tem todas as instruções para
                entender o funcionamento do Mobiltracker para PARCEIROS.
              </p>
            </>
          ) : (
            <>
              <br />
              <p>
                Porém, verificamos que ocorreu um erro de envio e pode ser que
                você não receba imediatamente o nosso email com todas as
                instruções, mas não se preocupe!
              </p>
              <br />
            </>
          )}

          <ContactUs />
          <p>Estamos à disposição!</p>
          <p>Atenciosamente.</p>
          <p>Equipe Mobiltracker</p>
        </div>
        <a
          className="btn-back"
          target="blank"
          href="https://mobilparceiros.com.br/"
        >
          Voltar
        </a>

        <div className="decoration"></div>
      </Success>
    </>
  );
}

const ContactUs = () => (
  <p>
    Nós logo entraremos em contato com você, mas a qualquer momento você pode
    falar conosco pelo e-mail{" "}
    <span>
      <a target="blank" href="mailto:parceiros@mobiltracker.com.br">
        parceiros@mobiltracker.com.br
      </a>
    </span>{" "}
    ou pelo WhatsApp comercial{" "}
    <span>
      <a
        target="blank"
        href="https://api.whatsapp.com/send?phone=5551982819595&text=Ol%C3%A1%2C%20tenho%20d%C3%BAvidas%20sobre%20os%20termos%20de%20aceite%20do%20Pr%C3%A9-Cadastro%20parceiros%20Mobiltracker."
      >
        clicando aqui
      </a>
    </span>{" "}
    que teremos o prazer de esclarecer sua dúvidas.
  </p>
);
