import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.2);
  padding: 2rem 4rem;
  max-width: 60rem;
  margin: 4rem auto;
  position: relative;

  .form {
    display: flex;
    flex-direction: column;
    color: #27325c;
  }
  .space-around {
    margin: 1rem 0.5rem;
  }

  .input-style {
    flex: 2;
    border: 0.1rem solid #c9c9c9;
    border-radius: 2rem;
    padding: 0.8rem 1rem;
  }

  .input-style:focus {
    outline: 0;
  }

  .label-style {
    font-size: 1rem;
    position: absolute;
    transition: top, transform, 0.2s ease-in-out;
    pointer-events: none;

    &:before {
      z-index: -1;
      content: "";
      position: absolute;
      width: 100%;
      height: 10%;
      top: 50%;
      background-color: #fff;
    }
  }
  .style-up {
    top: -0.6rem;
    left: 2rem;
    transform: scale(0.9);
  }
  .style-down {
    top: 0.8rem;
    left: 2.5rem;
    transform: scale(1);
  }

  .name-last-name {
    display: flex;

    .float-label {
      flex: 1;
    }
  }
  .float-label:focus-within label {
    top: -0.6rem;
    left: 2rem;
    transform: scale(0.9);

    &:before {
      background-color: #fff;
    }
  }
  .form-group {
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .name,
  .last-name {
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
  }

  .alert {
    background-color: rgba(39, 50, 92, 1);
    padding: 0.5rem 1rem;
    margin: 0rem 1rem;
    font-size: 0.8rem;
    border-radius: 0.1rem;
    border: none;
    position: relative;
    z-index: 2;
    border-radius: 0.3rem;
    color: #fff;

    &:before {
      border: none;
      background: linear-gradient(
        -45deg,
        transparent 50%,
        rgba(39, 50, 92, 1) 50%
      );
      z-index: -1;
      content: "";
      top: -0.3rem;
      left: 1rem;
      width: 0.5rem;
      height: 0.5rem;
      transform: rotate(45deg);
      position: absolute;
    }

    .link {
      margin-left: 0.2rem;
      font-weight: 500;
      color: #32b266;
    }
  }
  .form-phone-number {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .label-style {
      &:before {
        top: 48%;
      }
    }

    .float-label:focus-within label {
      top: -0.65rem;
    }
  }

  .call-phone {
    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
      padding-left: 1rem;
    }
  }
  .form-check {
    display: flex;
    align-items: center;

    p {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }

  .form-check-label {
    min-height: 1.2rem;
    min-width: 1.2rem;
    border: 0.1rem solid #c9c9c9;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;
    transition: all 0.2s ease-in-out;
  }
  input:checked ~ .form-check-label {
    background-color: #27325c;
    border: 0.1rem solid #27325c;
  }
  input:checked ~ .form-check-label:after {
    font-family: "FontAwesome";
    content: "\f00c";
    color: #fff;
    font-size: 0.6rem;
  }

  .radio-buttons-about {
    display: flex;
    justify-content: space-between;
  }
  .radio-buttons-content {
    background-color: #f1f1f1;
    border-radius: 2rem;
    margin: 0.5rem;
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    color: #27325c;

    h3 {
      margin-bottom: 1rem;
      width: 100%;
      font-size: 1rem;
      font-weight: 300;
      /* background-color: red; */
      text-align: center;
    }
  }
  .radio-yes-or-no {
    /* background-color: red; */
    display: flex;
    width: 80%;
    min-height: 3rem;
    align-items: center;
    justify-content: space-around;
  }

  .radio-yes,
  .radio-no {
    width: 40%;
    border: 0.01rem solid #d8d8d8;
    display: flex;
    position: relative;
    height: 2rem;
    overflow: hidden;
    border-radius: 1rem;
  }
  .radio-yes input,
  .radio-no input {
    display: none;
  }
  .radio-yes label,
  .radio-no label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 4rem;
    width: 100%;
    background-size: contain;
    background: linear-gradient(
      to bottom,
      white 0,
      white 2rem,
      #27325c 2rem,
      #27325c 8rem
    );
    position: absolute;
    top: 0;
    transition: 0.5s;

    span {
      display: flex;
      align-items: center;
      height: 50%;
    }
  }
  .radio-yes input:checked + label,
  .radio-no input:checked + label {
    background-position: 0 -2rem;
    color: #fff;
  }

  .btn-send-ok {
    align-self: center;
    width: 50%;
    height: 3rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    color: #fff;
    border: none;
    transition: all 0.3s ease-in-out;
  }
  .btn-send-ok:enabled:hover {
    background-color: #27325c;
  }
  .btn-send-ok:disabled {
    background-color: #c9c9c9;
  }
  .btn-send-ok:enabled {
    background-color: #32b266;
    cursor: pointer;
  }
  .alert-warning {
    max-width: 30rem;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: #27325c;

    &::before {
      left: 50%;
      background-color: #27325c;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 2rem;

    .name-last-name,
    .call-phone,
    .radio-buttons-about {
      flex-direction: column;
    }
    .form-check p {
      font-size: 0.8rem;
    }
    .call-phone small {
      padding-left: 0;
      font-size: 0.7rem;
    }
    .alert {
      &::before {
        top: -0.3rem;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    box-shadow: none;
    border-top: 0.1rem solid #c9c9c9;
  }
`;
