// tslint:disable: typedef
import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Rifm } from "rifm";
import axios from "axios";
import { rootUrl } from "./config";
import { Container } from "./styles/Form";

type PartnerData = {
  name: string;
  lastName: string;
  email: string;
  domainUrl: string;
  businessName: string;
  phone: string;
  scheduleCall: boolean;
  usedBefore: boolean | null;
  hasCentral: boolean | null;
};

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const isValidEmail = (email: string) => EMAIL_REGEX.test(email);
const isValidPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/[^0-9]/g, "").length === 11
    ? true
    : phoneNumber.replace(/[^0-9]/g, "").length === 10
    ? true
    : false;
const formatDomain = (domainUrl: string) =>
  domainUrl
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^A-Za-z0-9]/g, "-")
    .concat(".plataformatk.com.br");

const isPartnerDataValid = (partnerData: PartnerData) => {
  if (partnerData.name.length < 2) return false;
  if (partnerData.lastName.length < 2) return false;
  if (partnerData.businessName.length < 2) return false;
  if (partnerData.businessName.length > 30) return false;
  if (partnerData.domainUrl.length < 2) return false;
  if (partnerData.domainUrl.length > 30) return false;
  if (partnerData.domainUrl.includes("@")) return false;
  if (!isValidEmail(partnerData.email)) return false;
  if (!isValidPhoneNumber(partnerData.phone)) return false;
  if (partnerData.usedBefore === null) return false;
  if (partnerData.hasCentral === null) return false;
  return true;
};

type SubmitResponse =
  | {
      success: true;
      status: SuccessStatus;
    }
  | { success: false; errorMsg: string };

export type SuccessStatus = "OK" | "EMAIL_NOT_SENT";

type SuccessResponse = { status: SuccessStatus };

const submitForm: (p: PartnerData) => Promise<SubmitResponse> = async (
  partnerData: PartnerData
) => {
  if (!isPartnerDataValid(partnerData))
    return { success: false, errorMsg: "Dados invalidos" };

  var formattedData = {
    ...partnerData,
    phone: partnerData.phone.replace(/[^0-9]/g, ""),
    domainUrl: formatDomain(partnerData.businessName),
    affiliateCode: localStorage.getItem("affiliateCode"),
  };

  try {
    const response = await axios.post<SuccessResponse>(
      "api/registration",
      formattedData,
      {
        baseURL: rootUrl,
      }
    );

    return { success: true, status: response.data.status };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 404)
        return {
          success: false,
          errorMsg:
            "Ocorreu um erro de conexão. Por favor tente novamente mais tarde ou entre em contato com o suporte técnico",
        };

      if (
        error.response.status === 400 &&
        typeof error.response.data === "string"
      )
        return { success: false, errorMsg: error.response.data };
    }

    return {
      success: false,
      errorMsg:
        "Ocorreu um erro. Por favor tente novamente mais tarde ou entre em contato com o suporte técnico.",
    };
  }
};

export function RegisterScreen(props: {
  onSubmitSuccess: (status: SuccessStatus) => void;
}) {
  const [data, setData] = useState<PartnerData>({
    name: "",
    lastName: "",
    domainUrl: "",
    email: "",
    phone: "",
    businessName: "",
    scheduleCall: false,
    hasCentral: null,
    usedBefore: null,
  });

  const [formResultState, setFormResultState] = useState<
    SubmitResponse | "sending" | null
  >(null);
  const canSend = isPartnerDataValid(data);

  useEffect(function loadAffiliateCode() {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code !== null) {
      localStorage.setItem("affiliateCode", code);
    }
  }, []);

  return (
    <>
      <Container>
        <Header />
        <div className="form">
          <div className="name-last-name">
            <div className="form-group space-around float-label">
              <label
                className={
                  data.name === ""
                    ? "style-down label-style"
                    : "style-up label-style"
                }
                htmlFor="name"
              >
                Nome
              </label>
              <input
                id="name"
                type="text"
                className="input-style"
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                }}
                value={data.name}
                style={{
                  backgroundColor:
                    data.name === ""
                      ? "white"
                      : data.name.length > 2
                      ? "#D2F7CF"
                      : "#FFD6D6",
                }}
                required
              ></input>
            </div>
            <div className="form-group space-around float-label">
              <label
                className={
                  data.lastName === ""
                    ? "style-down label-style"
                    : "style-up label-style"
                }
                htmlFor="lastName"
              >
                Sobrenome
              </label>
              <input
                id="lastName"
                type="text"
                className="input-style"
                onChange={(e) => {
                  setData({ ...data, lastName: e.target.value });
                }}
                value={data.lastName}
                style={{
                  backgroundColor:
                    data.lastName === ""
                      ? "white"
                      : data.lastName.length > 2
                      ? "#D2F7CF"
                      : "#FFD6D6",
                }}
                required
              ></input>
            </div>
          </div>

          <div className="form-group space-around float-label">
            <label
              className={
                data.email === ""
                  ? "style-down label-style"
                  : "style-up label-style"
              }
              htmlFor="email"
            >
              E-mail
            </label>
            <input
              id="email"
              type="email"
              // placeholder={"seuemail@provedor.com"}
              className="input-style"
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              value={data.email}
              style={{
                backgroundColor:
                  data.email === ""
                    ? "white"
                    : isValidEmail(data.email)
                    ? "#D2F7CF"
                    : "#FFD6D6",
              }}
              required
            ></input>
          </div>
          <div className="form-group space-around float-label">
            <label
              className={
                data.businessName === ""
                  ? "style-down label-style"
                  : "style-up label-style"
              }
              htmlFor="businessName"
            >
              Nome do seu negócio
            </label>
            <input
              id="businessName"
              type="text"
              className="input-style"
              onChange={(e) => {
                setData({
                  ...data,
                  businessName: e.target.value,
                  domainUrl: e.target.value,
                });
              }}
              value={data.businessName}
              style={{
                backgroundColor:
                  data.domainUrl === ""
                    ? "white"
                    : data.businessName.length > 2 &&
                      data.businessName.length < 30
                    ? "#D2F7CF"
                    : "#FFD6D6",
              }}
              required
            ></input>
          </div>
          {data.domainUrl.length > 2 ? (
            <div className="alert">
              O nome de seu negócio será utilizado na criação de um subdomínio
              personalizado com a URL
              <strong>{" " + formatDomain(data.domainUrl)}</strong>
              <br />
              Veja um exemplo:
              <a
                className="link"
                href="http://mobinho.plataformatk.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                mobinho.plataformatk.com.br
              </a>
            </div>
          ) : null}
          {/* até aqui ta ok */}
          <div className="form-phone-number space-around float-label">
            <label
              className={
                data.phone === ""
                  ? "style-down label-style"
                  : "style-up label-style"
              }
              htmlFor="phoneNumber"
            >
              Número de telefone
            </label>
            <Rifm
              accept={/[0-9]/g}
              format={(s) => {
                const clear = s.replace(/[^0-9]/g, "").substring(0, 11);
                const ddd = clear.substring(0, 2);
                const f1 =
                  clear.length === 11
                    ? clear.substring(2, 7)
                    : clear.substring(2, 6);
                const f2 =
                  clear.length === 11
                    ? clear.substring(7, 11)
                    : clear.substring(6, 10);
                if (f1 && f2) return `(${ddd}) ${f1}-${f2}`;
                if (f1) return `(${ddd}) ${f1}`;
                return ddd;
              }}
              onChange={(e) => {
                setData({ ...data, phone: e });
              }}
              value={data.phone}
            >
              {({ value, onChange }) => (
                <input
                  id="phoneNumber"
                  type="text"
                  value={value}
                  className="input-style"
                  // placeholder={"(99) 99999-9999"}
                  onChange={onChange}
                  style={{
                    backgroundColor:
                      data.phone === ""
                        ? "white"
                        : isValidPhoneNumber(data.phone)
                        ? "#D2F7CF"
                        : "#FFD6D6",
                  }}
                />
              )}
            </Rifm>
            <div className="call-phone">
              <div className="form-check space-around">
                <input
                  hidden
                  id="agendcall_chk"
                  className="form-check-input"
                  type="checkbox"
                  onClick={(e) =>
                    setData({ ...data, scheduleCall: !data.scheduleCall })
                  }
                  defaultChecked={data.scheduleCall}
                />
                <label
                  htmlFor="agendcall_chk"
                  className="form-check-label"
                ></label>
                <p>Gostaria também de agendar uma chamada telefônica</p>
              </div>
              <small>Entraremos em contato via WhatsApp</small>
            </div>
          </div>
          <div className="space-around radio-buttons-about">
            <div className="radio-buttons-content">
              <h3>Já usou a plataforma Mobiltracker?</h3>
              <div className="radio-yes-or-no">
                <div
                  className="radio-yes"
                  onClick={() => setData({ ...data, usedBefore: true })}
                >
                  <input
                    type="radio"
                    checked={data.usedBefore === true}
                    onChange={(e) => setData({ ...data, usedBefore: true })}
                  />
                  <label>
                    <span>Sim</span>
                  </label>
                </div>
                <div
                  className="radio-no"
                  onClick={() => setData({ ...data, usedBefore: false })}
                >
                  <input
                    type="radio"
                    checked={data.usedBefore === false}
                    onChange={(e) => setData({ ...data, usedBefore: false })}
                  />
                  <label>
                    <span>Não</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="radio-buttons-content">
              <h3>Já tem uma central ou presta serviços de rastreamento?</h3>
              <div className="radio-yes-or-no">
                <div
                  className="radio-yes"
                  onClick={() => setData({ ...data, hasCentral: true })}
                >
                  <input
                    type="radio"
                    checked={data.hasCentral === true}
                    onChange={(e) => setData({ ...data, hasCentral: true })}
                  />
                  <label>
                    <span>Sim</span>
                  </label>
                </div>
                <div
                  className="radio-no"
                  onClick={() => setData({ ...data, hasCentral: false })}
                >
                  <input
                    type="radio"
                    checked={data.hasCentral === false}
                    onChange={(e) => setData({ ...data, hasCentral: false })}
                  />
                  <label>
                    <span>Não</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn-send-ok"
            disabled={!canSend || formResultState === "sending"}
            type="submit"
            onClick={() => {
              setFormResultState("sending");
              submitForm(data)
                .then((s) => {
                  if (s.success) {
                    props.onSubmitSuccess(s.status);
                  } else {
                    setFormResultState(s);
                  }
                })
                .catch((s) =>
                  setFormResultState({
                    success: false,
                    errorMsg:
                      "Ocorreu um erro de conexão. Por favor tente novamente mais tarde.",
                  })
                )
                .finally(() => {
                  formResultState === "sending" && setFormResultState(null);
                });
            }}
          >
            {formResultState === "sending" ? "Enviando..." : "Enviar"}
          </button>
        </div>

        {formResultState &&
          formResultState !== "sending" &&
          formResultState.success === false && (
            <div className="alert alert-warning text-center">
              {formResultState.errorMsg}
            </div>
          )}
      </Container>
    </>
  );
}
